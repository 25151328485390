.toggle-switch-wrapper {
}
.toggle-switch-container {
  width: 250px;
  height: 50px;
  background-color: var(--toggle-switch-bg);
  border-radius: 60px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.toggle-switch-button {
  width: 78px;
  height: 25px;
  border-radius: 40px;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-blue);
  -webkit-user-select: none;
}

.toggle-switch-on {
  background-color: var(--primary-blue);
  color: var(--white);
}
