.date-picker-wrapper {
    width: 250px;
    height: 45px;
    border: 2px solid var(--lite-border);
    border-radius: 10px;
    position: relative;
    margin-top: 15px;
}

.not-valid {
    border: 2px solid var(--error-red);
}

.date-picker-wrapper label {
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    left: 20px;
    top: -10px;
    background-color: var(--white);
    padding: 0px 10px;
}

.date-picker-wrapper label span {
    color: var(--error-red);
}

.date-picker-wrapper input {
    background-color: none;
    border: none;
    position: absolute;
    left: 25px;
    top: 12px;
    width: 85%;
}

.date-picker-wrapper input:focus {
    outline: none;
    border: none;
}

.date-picker-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('../../../assets/icons/calendar.png') no-repeat;
    width: 20px;
    height: 20px;
}