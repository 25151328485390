.pallet-card-container {
  display: grid;
  gap: 16px;
  border-radius: 20px;
  background-color: var(--white);
  margin: 30px;
}

.flex-column {
  flex-direction: column;
}

.pallet-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  min-width: 200px;
  border-radius: 12px;
  padding: 12px;
  background-color: var(--liteBlue);
}

.pallet-card:nth-child(3) {
  flex-basis: 100%;
}

.pallet-card.invisible {
  visibility: hidden;
}

.pallet-details-container {
  text-align: left;
  margin-top: -5px;
}

.pallet-details-container p {
  line-height: 0.8;
}

.pallet-label {
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-text);
}

.pallet-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px !important;
  overflow-wrap: anywhere;
}
