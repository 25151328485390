.popup-header-block-rack {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popup-title-block-rack {
  font-size: 24px;
  font-weight: 500;
  color: var(--primaryBlue);
  text-align: left;
}

.popup-close-block-rack {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-wrapper-block-rack {
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  position: fixed;
  z-index: 1008;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  webkit-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container-block-rack .dock-loader-block-rack {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--modal-blue-bg);
}

.spinner-block-rack {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.popup-container-block-rack {
  width: 95vw;
  max-height: 90vh;
  margin-bottom: 10px;
  background-color: var(--modal-blue-bg);
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.overflow-scroll-block-rack {
  overflow: scroll !important;
}

.popup-section-block-rack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: var(--white);
}

.block-details-block-rack {
  background-color: var(--white);
  margin-bottom: 15px;
  padding: 1px 20px 5px 20px;
}

.rack-split-section-block-rack {
  display: flex;
  flex-direction: row;
}

.left-section-wrapper-block-rack,
.right-section-wrapper-block-rack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.left-flex-block-rack {
  flex: 0.7;
}

.right-flex-block-rack {
  flex: 0.3;
  height: 100%;
  width: 100%;
}

.section-title-block-rack {
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  color: var(--secondary-text);
}

.block-table-block-rack {
  overflow-y: scroll;
}

.block-table-size-block-rack {
  width: 100%;
  max-height: 50vh;
  flex: 1;
}

.rack-table-size-block-rack {
  width: 98%;
  margin-top: 1px;
  max-height: 100vh;
  /* flex: 0.7; */
}

.rack-table-block-rack {
  flex: 0.3;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: var(--white);
  min-height: 200px;
  min-width: 200px;
}

.rack-row-block-rack {
  margin: 10px;
  width: 170px;
  /* height: 130px; */
  border-radius: 12px;
  padding: 12px;
  background-color: var(--liteBlue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.empty-row {
  width: 100%;
  height: 100%;
  /* background-color: green; */
  color: black;
}

.rack-details-block-rack {
  text-align: left;
  margin-top: -5px;
}

.rack-details-block-rack p {
  line-height: 0.8;
}

.rack-details-title-block-rack {
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-text);
}

.rack-details-value-block-rack {
  font-weight: 500;
  font-size: 14px;
}

.popup-section-title-block-rack {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--tertiary-text);
}

.details-section-block-rack {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#block-location-table-block-rack {
  width: 115%;
  /* height: 300px; */
  border-collapse: collapse;
  margin-top: 10px;
  overflow: scroll;
}

#block-location-table-block-rack td {
  border-top: 1px solid var(--table-border);
  border-bottom: 1px solid var(--table-border);
}

.block-table-header-block-rack {
  max-width: 110px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--primaryBlue);
  text-align: left;
  padding-left: 10px;
}

.block-table-data-block-rack {
  height: 50px;
  max-width: 130px;
  text-wrap: wrap;
  max-height: 10px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 0px;
  text-align: left;
  color: var(--secondary-text);
}

.block-table-data-md {
  min-width: 110px;
}

.align-center-block-rack {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality-block-rack {
  width: 116px;
  height: 36px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality-text-block-rack {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

thead {
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  line-height: 24px;
  border-bottom: 1px solid var(--table-border);
}

.available-pallet {
  color: var(--available);
  background-color: var(--available-bg);
}

.hold-pallet {
  color: var(--hold);
  background-color: var(--hold-bg);
}

.manual-pallet {
  color: var(--manual);
  background-color: var(--manual-bg);
}

.scrap-pallet {
  color: var(--scrap);
  background-color: var(--scrap-bg);
}

.shipped-pallet {
  color: var(--shipped);
  background-color: var(--shipped-bg);
}

.removed-pallet {
  color: var(--removed);
  background-color: var(--removed-bg);
}

.empsale-pallet {
  color: var(--empsale);
  background-color: var(--empsale-bg);
}

.non-conform-pallet {
  color: var(--non-conform);
  background-color: var(--non-conform-bg);
}

.received-pallet {
  color: var(--received);
  background-color: var(--received-bg);
}

.quality-pallet {
  color: var(--quality);
  background-color: var(--quality-bg);
}

.destruction-pallet {
  color: var(--destruction);
  background-color: var(--destruction-bg);
}

.consumed-pallet {
  color: var(--consumed);
  background-color: var(--consumed-bg);
}

.error-pallet {
  color: var(--error);
  background-color: var(--error-bg);
}

.incubation-pallet {
  color: var(--incubation);
  background-color: var(--incubation-bg);
}

.null-pallet {
  color: var(--white);
  background-color: var(--grey);
}

.error-red-font {
  font-size: 16px;
  color: var(--error-red) !important;
}

.success-green-font {
  font-size: 16px;
  color: var(--available) !important;
}

.details-block-rack {
  margin-right: 50px;
  margin-bottom: -20px;
  text-align: left;
  min-width: 150px;
}

.details-data-title-block-rack {
  font-size: 14px;
  font-weight: 400;
  color: var(--tertiary-text);
}

.details-data-block-rack {
  font-weight: 500;
  font-size: 16px;
  color: var(--grey);
  margin-top: -10px;
}

.lgv-icon-style-block-rack {
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style-block-rack {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style-sm-block-rack {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-loader {
  height: 100px;
  margin: 0px 30px 0px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.title-extra-space {
  padding: 0px 7px;
}

.block-loader .spinner {
  margin-left: 10px;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}
