.time-machine-container {
  margin: 0px 30px 30px 30px;
  /* height: 600px; */
  height: 70vh;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 10px;
  user-select: none;
  position: relative;
  overflow: scroll;
}

#time-machine-map-id-reference {
  transform: scale(1);
}

.time-machine-container .indoor-map {
  height: 100%;
  border-radius: 10px;
  border: none;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--white);
  z-index: 1000;
  border-radius: 10px;
}

.lgv-marker {
  /* transition: 0.3s; */
  z-index: 1200 !important;
}

.popup-title {
  font-size: 20px;
  font-weight: 500;
}

/* @keyframes fade { 
      from { opacity: 0.5; } 
  }
  
  .blinking {
      animation: fade 1s infinite alternate;
  } */

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

/* .time-options-section {
    display: none;
  } */
.new-ui .time-options-section {
  display: flex;
  position: absolute;
  right: 38px;
  top: -57px;
}

.time-machine .time-options-section {
  display: none;
}
.new-ui .time-options-section {
  display: flex;
  position: absolute;
  right: 38px;
  top: -57px;
}

.new-ui .pallet {
  background-color: #f33106;
}

.new-ui [class*="rack"].pallet {
  background-color: #000000;
}

#time-machine-map-container-id .leaflet-pane {
  scale: 0.98;
}

.time-machine-sections {
  padding: 5px 25px 25px;
  height: 40px;
  row-gap: 5px;
}

.toggle-controller-section h4 {
  margin: 0px 5px 0 0;
}

.time-machine .flexible-right-screen {
  display: none;
}
.time-machine .flexible-left-screen {
  flex: 1;
}
