.heatmap-modal-wrapper {
  position: fixed;
  z-index: 1008;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heatmap-modal-container {
  padding: 5px;
  width: 98%;
  background-color: var(--white);
  height: 80%;
  border-radius: 25px;
  position: relative;
}

.float-options-right-section {
  display: flex;
  flex-direction: row;
}

.floating-heatmap-options {
  z-index: 1006;
  width: 98%;
  height: 110px;
  margin: 15px 0px;
  position: absolute;
  top: -70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.close-btn,
.camera-btn {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  transition: 0.2s all;
}

.close-btn:hover,
.camera-btn:hover {
  background: rgba(255, 255, 255, 1);
}

.close-btn:active {
  background-color: var(--close-btn-active-bg);
}

.close-btn img,
.camera-btn img {
  width: 13px;
  height: 13px;
}

#camera-btn {
  margin-right: 10px;
}

.disabled-camera {
  cursor: not-allowed;
}

#camera-btn img {
  width: 25px;
  height: 25px;
}

.heatmap-progress-bar-wrapper {
  display: flex;
  position: relative;
  bottom: -7px;
  width: 100%;
  height: 60px;
  z-index: 1009;
  justify-content: center;
  align-items: center;
}

.heatmap-progress-bar-container {
  display: flex;
  background-color: var(--white);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.seek-container {
  flex: 0.1;
}

.progress-bar-container {
  flex: 0.9;
}

input[type="range"] {
  accent-color: var(--primary-blue);
}

#heatmapProgressSeekbarId::-webkit-slider-thumb {
  pointer-events: auto;
}

#heatmapProgressSeekbarId::-moz-range-thumb {
  pointer-events: auto;
}

#heatmapProgressSeekbarId::-ms-thumb {
  pointer-events: auto;
}
.heatmap-progress-seek-bar {
  padding-right: 10px;
}

.heatmap-progress-seek-bar {
  padding-right: 10px;
  width: 95%;
}
