#customize-layout .layout-container {
  width: 500px;
  border-radius: 25px;
  background-color: var(--white);
  position: absolute;
  right: 0;
  margin-top: 120px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  z-index: 1003;
}

#customize-layout .layout-text-container h3,
#customize-layout .layout-text-container p {
  margin: 5px;
}

#customize-layout .layout-text-container {
  display: flex;
  gap: 32px;
  padding: 11px 24px;
}

#customize-layout .layout-text-container .left-content span {
  font-size: 38px;
}

#customize-layout .layout-option.selected {
  width: 100%;
  display: block;
  background: rgba(68, 97, 242, 1);
}

#customize-layout .layout-option.selected:hover {
  background: rgba(24, 57, 224, 1);
}

#customize-layout .layout-option .right-content h3 {
  font-size: 16px;
  text-align: left;
}
#customize-layout .layout-option .right-content p {
  font-size: 13px;
  text-align: left;
}
#customize-layout .layout-container {
  background-color: #f1f3f5;
}

.layout-container {
  /* width: 630px;
  height: 334px; */
}

#customize-layout .layout-option {
  border-radius: 12px;
  background: white;
  cursor: pointer;
}
#customize-layout .layout-option:hover {
  background: rgba(68, 97, 242, 0.3);
}

#customize-layout .layout-selection-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 20px;
  overflow: hidden;
}

#customize-layout .customize-modal-top-section {
  justify-content: space-between;
  padding: 0 0 0 20px;
}
#customize-layout .customize-modal-top-section img {
  margin-top: 1px;
}
