.button-container {
  background-color: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  border-radius: 30px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  margin: 0px 7px;
  overflow: hidden;
}
.button-container .spinner {
  width: 20px;
  height: 20px;
}
.button-container-active {
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
}
.button-container-loading {
  width: 100px;
  cursor: not-allowed;
  color: var(--black);
  background-color: var(--disabled-loading-state);
}
.button-container img {
  padding-right: 7px;
}
.button-disabled {
  pointer-events: none;
}

@media only screen and (max-width: 1000px) {
  .button-container {
    font-size: 10px;
  }
}
