.dock-door-container {
  height: 50px;
  position: absolute;
  right: 50px;
  border-radius: 10px;
  z-index: 1002;
  background-color: var(--modal-blue-bg);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
}

.dock-door-at-bottom {
  bottom: 60px;
}

.dock-door-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dock-door .dock-container {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 7px 7px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0px 10px; */
  overflow-y: hidden;
  z-index: 1000 !important;
}

.dock-door.dock-door-with-90-angle .dock-container {
  flex-direction: row;
  position: relative;
  border-radius: 7px 4px 4px 7px !important;
  /* height: var(--dock-door-width);
  width: var(--dock-door-height); */
}

.dock-door-with-90-angle .dock-top-section > div {
  padding: 3.5px 3px;
  line-height: 8px;
  rotate: -90deg;
  height: auto;
}

.dock-door-with-90-angle .dock-top-section {
  border-top: 0 !important;
  border-bottom: 0 I !important;
}
.dock-door-with-90-angle .dock-big-complex-left,
.dock-door-with-90-angle .dock-big-complex-right {
  margin: 0.6px 0px;
}
.dock-door.dock-door-with-90-angle .dock-top-section {
  flex-direction: row;
  border-right: 1px solid var(--lite-border);
  border-left: 1px solid var(--lite-border);
}

.dock-door-with-90-angle .dock-bottom-section {
  flex-direction: row;
  height: 100%;
}

.dock-door-with-90-angle .dock-small {
  width: 3px;
  height: 40%;
  border-radius: 1px;
}

.dock-door-with-90-angle .dock-big {
  width: 2.5px;
  height: 85%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dock-door-with-90-angle .dock {
  margin-left: 1.6px;
}

.ready-to-load .dock-container {
  border: 1px solid var(--available);
}

.not-ready-to-load .dock-container {
  border: 1px solid var(--lite-border);
}

.dock-door .dock-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.2;
  font-size: 6px;
  border-top: 1px solid var(--lite-border);
  border-bottom: 1px solid var(--lite-border);
  width: 100%;
  background-color: var(--liteBlue);
}

.dock-top-section .dock-top-section-operation {
  width: 100%;
  height: 15px;
  border-bottom: 1px solid var(--lite-border);
  font-size: 10px;
  text-align: center;
}

.dock-door-auto {
  background-color: var(--flu-green);
}

.dock-door-manual {
  background-color: var(--flu-mustard);
}

.dock-bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.8;
  overflow-y: hidden;
}

.dock-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.dock {
  margin: 0.9px 0px;
  color: transparent;
  font-size: 0.2px;
}

.dock-small {
  width: 40%;
  height: 3px;
  border-radius: 1px;
}

.dock-big {
  width: 85%;
  height: 2.5px;
  border-radius: 1px;
  display: flex;
  flex-direction: row;
}

.dock-big-complex-left,
.dock-big-complex-right {
  flex: 1;
  margin: 0px 0.6px;
  border-radius: 1px;
}

.allocatedview-link-container {
  background-color: var(--white);
  border-radius: 30px;
  padding: 10px 15px;
  height: 20px;
}

.allocated-view-link {
  display: flex;
  gap: 10px;
  color: var(--black);
}

.legend-btn {
  padding: 10px 15px;
  height: 20px;
  /* border: 1px solid var(--primary-blue); */
}

.dock-door-container .dock-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

/* .leaflet-marker-icon {
  overflow-y: hidden;
} */

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
