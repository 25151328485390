.map-modal-wrapper {
  position: fixed;
  z-index: 1008;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-modal-container {
  padding: 5px;
  width: 80%;
  background-color: var(--white);
  height: 80%;
  border-radius: 25px;
  position: relative;
}

.floating-map-options {
  z-index: 1006;
  width: 99%;
  height: 50px;
  margin: 15px 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.float-options-left-section {
  background-color: #f1f3f5;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  margin-left: 10px;
}

.float-options-left-section .site-selection-title {
  font-weight: 500;
  padding-right: 30px;
  border-right: 0.5px solid #a4a4a4;
  margin-right: 25px;
}

.location-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.location-selection p {
  font-weight: 400;
  font-size: 12px;
}

.selected-location-section {
  padding: 5px;
}

.disable-click {
  user-select: none !important;
  cursor: default !important;
}

.modal-map-container {
  height: 100%;
  width: 100%;
}

.close-button {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-button img {
  width: 13px;
  height: 13px;
}

.leaflet-control-zoom .leaflet-bar .leaflet-control {
  margin-top: 50px !important;
}

@media only screen and (max-width: 768px) {
  .float-options-left-section {
    flex-wrap: wrap;
  }

  .site-selection-title {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .location-selection p {
    display: none;
  }
}

@media only screen and (max-width: 330px) {
  .customize-container {
    margin-right: 13px;
  }
}

@media only screen and (min-width:1920px) {
.map-modal-container{
  height:95%;
}
}