.customize-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    z-index: 1009;
    position: fixed;
    top: 0;
}

.customize-container {
    width: 300px;
    border-radius: 25px;
    background-color: var(--white);
    position: absolute;
    right: 0;
    margin-top: 120px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    z-index: 1003;
}

.customize-modal-top-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}

.customize-modal-top-section img {
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-top: 15px;
}

.customize-modal-middle-section {
    flex: .8;
    display: flex;
    flex-direction: column;
    padding: 10px 25px 30px 25px;
}


.customize-modal-bottom-section {
    flex: .2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--faded-primary-blue);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 10px;
}

.customize-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.customize-buttons p {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px
}

.customize-modal-bottom-section .cus-btn {
    color: var(--primary-blue);
    height: 30px;
    width: 120px;
    font-weight: 500;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 22px;
}
  /* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18.5px;
    width: 18.5px;
    left: 4px;
    bottom: 2px;
    right: 2px;
    top: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--primary-blue);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--primary-blue);
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

[data-tip] {
	position:relative;

}
[data-tip]:before {
	content:'';
	/* hides the tooltip when not hovered */
	display:none;
	content:'';
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #1a1a1a;	
	position:absolute;
	top:30px;
	left:35px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
[data-tip]:after {
	display:none;
	content:attr(data-tip);
	position:absolute;
	top:35px;
	left:0px;
	padding:5px 8px;
	background:#1a1a1a;
	color:#fff;
	z-index:9;
	font-size: 0.75em;
	height:18px;
	line-height:18px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	white-space:nowrap;
	word-wrap:normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}

@media only screen and (max-width: 768px) {
    .customize-buttons p {
        font-size: 14px;
    }

    .customize-modal-bottom-section .cus-btn {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 330px) {
    .customize-container {
        margin-right: 13px;
    }
}

@media only screen and (min-width: 2560px) {
    .customize-container {
        margin-right: 30vw;
    }
}