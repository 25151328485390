.yard-dock-door {
  border: 1px solid;
  box-sizing: border-box;
}

.yard-dock-door.horizontal .yard-dock-icon-container {
  display: flex;
  justify-content: center;
}

.yard-dock-door.horizontal .yard-dock-top-section {
  flex: 1 1 70%;
  background: rgb(195, 195, 195);
}

.yard-dock-door.horizontal .yard-dock-bottom-section {
  flex: 1 0 25%;
  border-left: 1px solid;
}

.yard-dock-bottom-section {
  background: white;
}
/* .yard-dock-door.horizontal {
  top: 150px;
} */

.yard-dock-door.vertical .yard-dock-icon-container {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.yard-dock-door.vertical .yard-dock-top-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  flex: 1;
}

.yard-dock-door.vertical .yard-dock-top-section-number {
  white-space: nowrap;
  align-self: center;
  position: relative;
  left: -1px;
  rotate: 90deg;
}

.yard-dock-door.vertical .yard-dock-progress-container {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
}

.yard-dock-door.vertical .yard-dock-bottom-section {
  border-top: 1px solid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.yard-dock-door.vertical .yard-dock-small {
  rotate: 90deg;
  padding: 2px;
  white-space: nowrap;
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  rotate: 90deg;
}

.yard-dock-small,
.yard-dock-top-section-number {
  /* line-height: 15px; */
  font-size: 5px;
}
.yard-dock-icon-container {
  width: 100%;
  height: 100%;
}

.yard-dock-progress-container {
  background: white;
}

.yard-dock-progress {
  background: rgb(255, 165, 0);
}

.yard-dock-door.horizontal .yard-dock-top-section-number {
  line-height: 7.6px;
}

.yard-dock-door.horizontal .yard-dock-small {
  line-height: 11px;
}

.yard-dock-icon-containerr {
  display: none !important;
}
