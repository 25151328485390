.view-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.legend-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.legend-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #DBE0E5;
    border-radius: 12px;
    padding: 10px;
    padding-left: 3%;
    width: 25%;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
}

.legend-info {
    display: flex;
    gap: 20px;
    align-items: center;
}

.view-legend-color {
    width: 30px;
    height: 10px;
    border-radius: 12px;
}

.view-symbol {
    background-color: #dbddde;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-width {
    width: 70%;
    bottom: 5%;
}

@media only screen and (max-width: 1300px) {
    .custom-width {
        width: 90%;

    }

}