.popup-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  position: fixed;
  z-index: 1008;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  webkit-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container {
  width: 95vw;
  height: 90vh;
  margin-bottom: 10px;
  background-color: var(--liteBlue);
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.popup-header {
  flex: 0.1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}
.popup-bottom {
  flex: 0.9;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 20px;
}

.left-section-scroller {
  overflow: scroll !important;
}

.left-selector-section {
  flex: 0.15;
  display: flex;
  align-items: flex-start;
  overflow: auto;
}
.storage-list {
  list-style: none;
  list-style-type: none;
  padding-right: 30px;
}
.storage-list-item {
  border-radius: 10px;
  height: 45px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  margin: 20px 0px;
  cursor: pointer;
  user-select: auto;
}



.storage-list-item:link {
  width: 80px;
  background-color: var(--faded-primary-blue);
  color: var(--primary-color);
}

.unselected {
  width: 110px;
  background-color: var(--faded-primary-blue);
  color: var(--primary-color);
}
.selected {
  width: 130px;
  background-color: var(--primary-blue);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected img {
  margin-right: 10px;
}

.right-display-section {
  align-items: center;
  justify-content: center;
  flex: 0.85;
  cursor: auto;
  user-select: auto;
  overflow-y: scroll;
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--primaryBlue);
  text-align: left;
}

.popup-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container .dock-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--modal-blue-bg);
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.popup-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: var(--white);
}

.block-details {
  background-color: var(--white);
  margin-bottom: 15px;
  padding: 1px 20px 5px 20px;
}

.rack-split-section {
  display: flex;
  flex-direction: row;
}

.left-section-wrapper,
.right-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.left-flex {
  flex: 0.7;
}
.right-flex {
  flex: 0.3;
}

.section-title {
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
}

.block-table {
  overflow-y: scroll;
}

.block-table-size {
  width: 100%;
  max-height: 50vh;
  flex: 1;
}
.rack-table-size {
  width: 98%;
  margin-top: 1px;
  max-height: 100vh;
  flex: 0.7;
}

.rack-table {
  flex: 0.3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: var(--white);
}

.rack-row {
  margin: 10px;
  width: 170px;
  height: 130px;
  border-radius: 12px;
  padding: 12px;
  background-color: var(--liteBlue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.rack-details {
  text-align: left;
  margin-top: -5px;
}

.rack-details p {
  line-height: 0.2;
}

.rack-details-title {
  font-weight: 400;
  font-size: 14px;
}

.rack-details-value {
  font-weight: 500;
  font-size: 14px;
}

.popup-section-title {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--tertiary-text);
}

.details-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#block-location-table {
  width: 100%;
  height: 300px;
  border-collapse: collapse;
  margin-top: 10px;
  overflow: scroll;
}

#block-location-table td {
  border-top: 1px solid var(--table-border);
  border-bottom: 1px solid var(--table-border);
}

.block-table-header {
  max-width: 110px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--primaryBlue);
  text-align: left;
  padding-left: 10px;
}

.block-table-data {
  max-width: 130px;
  text-wrap: wrap;
  max-height: 40px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  color: var(--secondary-text);
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality {
  width: 116px;
  height: 36px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality-text {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

thead {
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  line-height: 24px;
  border-bottom: 1px solid var(--table-border);
}

.available-pallet {
  color: var(--available);
  background-color: var(--available-bg);
}

.hold-pallet {
  color: var(--hold);
  background-color: var(--hold-bg);
}

.manual-pallet {
  color: var(--manual);
  background-color: var(--manual-bg);
}

.scrap-pallet {
  color: var(--scrap);
  background-color: var(--scrap-bg);
}

.shipped-pallet {
  color: var(--shipped);
  background-color: var(--shipped-bg);
}

.removed-pallet {
  color: var(--removed);
  background-color: var(--removed-bg);
}

.empsale-pallet {
  color: var(--empsale);
  background-color: var(--empsale-bg);
}

.non-conform-pallet {
  color: var(--non-conform);
  background-color: var(--non-conform-bg);
}

.received-pallet {
  color: var(--received);
  background-color: var(--received-bg);
}

.quality-pallet {
  color: var(--quality);
  background-color: var(--quality-bg);
}

.destruction-pallet {
  color: var(--destruction);
  background-color: var(--destruction-bg);
}

.consumed-pallet {
  color: var(--consumed);
  background-color: var(--consumed-bg);
}

.error-pallet {
  color: var(--error);
  background-color: var(--error-bg);
}

.incubation-pallet {
  color: var(--incubation);
  background-color: var(--incubation-bg);
}

.null-pallet {
  color: var(--white);
  background-color: var(--grey);
}

.error-red-font {
  color: var(--error-red) !important;
}

.success-green-font {
  color: var(--available) !important;
}

.details {
  margin-right: 50px;
  margin-bottom: -20px;
  text-align: left;
  min-width: 150px;
}

.details-data-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--tertiary-text);
}

.details-data {
  font-weight: 500;
  font-size: 16px;
  color: var(--grey);
  margin-top: -10px;
}

.lgv-icon-style {
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style-sm {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.non-lgv-table-loader {
  height: 100px;
  margin: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.non-lgv-table-spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

li.storage-list-item {
  width: unset;
  padding: 0px 10px;
}

.right-display-section {
  flex: 1 1;
}

.left-selector-section {
  flex: unset;
}
