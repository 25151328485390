.map-container {
  margin: -10px 10px 0px 10px;
  height: 80vh;
  background-color: var(--white);
  border-radius: 10px;
  webkit-user-select: none;
  position: relative;
  overflow: hidden;
}

.heat-map-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.loader-text {
  margin-top: -10px;
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1008;
  border-radius: 20px;
  background-color: var(--faded-primary-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#indoor-map-id-reference {
  position: relative;
  transform: scale(1);
  overflow: hidden;
}

/* #indoor-map-id-reference {
  .leaflet-overlay-pane {
    position: relative;
    z-index: auto;
  }

  .leaflet-marker-pane {
    z-index: auto;
  }

  .leaflet-map-pane {
    position: relative;
  }

  .leaflet-pane svg {
    position: relative;
    z-index: 1000 !important;
  }

  .pallets-wrapper-row {
    z-index: 0 !important;
  }
} */

.map-container::-webkit-scrollbar {
  display: none;
}

.map-container .indoor-map {
  height: 100%;
  border-radius: 10px;
  border: none;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--white);
  z-index: 1000;
  border-radius: 10px;
}

.lgv-icon-styles {
  display: flex;
  background-color: #2b2b2b;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.popup-title {
  font-size: 20px;
  font-weight: 500;
}

.map-container-wrapper {
  position: relative;
}

.expand-list-wrapper {
  display: flex;
  flex-direction: row;
}

.minimized-objects-expanded-view {
  background-color: var(--modal-blue-bg);
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0 -3px 10px rgb(0 0 0 / 0.2);
  z-index: 1000;
  overflow-x: hidden;
  margin-right: 1px;
  margin-bottom: 100px;
  position: absolute;
  bottom: -40px;
}

.minimized-objects-expanded-view-lgv-width {
  min-width: 180px;
}

.minimized-objects-expanded-view-dock-width {
  min-width: 250px;
}

.minimized-objects-expanded-view-production-line-width {
  min-width: 250px;
  margin-left: 30px;
}

.minimized-objects-follow-lgv-extra-bottom-space {
  bottom: -28px !important;
}

.minimized-objects-expanded-view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.minimized-objects-expanded-view-header-title {
  font-weight: 500;
  font-size: 16px;
  color: var(--primaryBlue);
}

.minimized-objects-expanded-view-header-close {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: var(--primaryBlue);
}

/* .minimized-objects-expanded-view-header-close:focus {
  font-weight: 400;
  font-size: 14px;
  color: var(--error);
  background-color: var(--table-badge-poor);
} */

.minimized-objects-expanded-view-body-item {
  background-color: var(--white);
  border-radius: 64px;
  margin: 10px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  cursor: pointer;
  transition: 0.2s all ease;
}

.minimized-objects-expanded-view-body-item:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.minimized-objects-expanded-view-body-item:hover
  .object-items-actions-buttons-container {
  display: flex;
  transition: 0.2s all ease;
}

.minimized-objects-expanded-view-body-item span {
  color: var(--primaryBlue);
  font-weight: 600;
  font-size: 14px;
}

.minimized-objects-expanded-view-header-section {
  /* background-color: green; */
}

.minimize-card-img {
  width: 12px;
  height: 12px;
}

.object-items-actions-buttons-container {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.margin-left-5 {
  margin-left: 5px;
}

.minimized-objects-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 100px;
  bottom: 0;
}

.minimized-objects-container {
  height: 50px;
  padding: 10px 20px;
  background-color: var(--liteBlue);
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0 -3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.minimized-objects-row-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.minimize-card-container {
  height: 20px;
  padding: 12px 16px 12px 20px;
  background-color: var(--white);
  border-radius: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 20px;
}

.minimize-card-container-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
}

.minimize-card-container-count-container {
  width: 18px;
  height: 18px;
  border-radius: 40px;
  padding: 5px;
  gap: 10px;
  background-color: var(--primary-blue);
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minimize-card-container-count {
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  padding-top: 3px;
}

.extra-space-badge {
  width: 50px !important;
}

.minimize-card-container-toggler {
  width: 18px;
  height: 18px;
  border-radius: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.5s all;
}

.minimize-card-container-toggler:focus,
.minimize-card-container-toggler:hover {
  background-color: var(--faded-primary-blue);
}

.minimize-card-container-toggler:active {
  background-color: var(--close-btn-active-bg);
}

.rotate-up-arrow-on-click {
  transition: ease 0.5s all;
  transform: rotate(0deg);
}

.rotate-down-arrow-on-click {
  transition: ease 0.5s all;
  transform: rotate(180deg);
}

.following-lgv-card-container-toggler {
  /* width: 18px; */
  padding: 5px;
  height: 18px;
  border-radius: 40px;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.5s all;
}

.following-lgv-card-container-toggler:focus,
.following-lgv-card-container-toggler:hover {
  background-color: var(--faded-primary-blue);
  color: var(--primary-blue);
}

.following-lgv-card-container-toggler:active {
  background-color: var(--close-btn-active-bg);
  color: var(--primary-blue);
}

.followed-objects-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  right: 100px;
  bottom: 0;
}

.followed-objects-container {
  height: 50px;
  padding: 10px 20px;
  background-color: var(--liteBlue);
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0 -3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-control-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50px;
  top: 10px;
}

.zoom-controls {
  width: 35px;
  height: 35px;
  background-color: white;
  font-size: 24px;
  border: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  cursor: pointer;
}

.zoom-in-control {
  border-radius: 10px 10px 0px 0px;
}

.zoom-out-control {
  border-radius: 0px 0px 10px 10px;
}

.zoom-reset-controls {
  margin-top: 5px;
  border-radius: 10px;
}

.zoom-reset-controls img {
  width: 17px;
  height: 17px;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.custom-marker-cluster {
  width: 50px;
  height: 10px;
  background-color: rgb(21, 91, 205);
  color: black;
  text-align: center;
}

.pallets-wrapper-col {
  z-index: -1000 !important;
  /* width: 100px; */
  display: flex;
  flex-direction: column;
}

.pallet-translateX {
  /* transform: translateX(-135%); */
  transform: scaleX(-1) translateX(130%);
}

.pallet-single-translateX {
  /* transform: translateX(-135%); */
  transform: scaleX(-1) translateX(145%);
}

.shift-left-2 {
  left: -6px;
}

.pallets-wrapper-row {
  position: relative;
  z-index: -1000 !important;
  display: flex;
  flex-direction: row;
  margin: 0px 0px;
  /* background-color: purple; */
}

.single-pallet-wrapper {
  margin: 0.5px 0px;
}

.pallets-wrapper-single-row {
  position: relative;
  z-index: -1000 !important;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  text-align: center;
}

.pallets-wrapper-single-row-reverse {
  position: relative;
  z-index: -1000 !important;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 5px;
  text-align: center;
}

.rotate-rack .pallet.pallet-single {
  rotate: 180deg;
}

.pallets-wrapper-row-reverse {
  position: relative;
  z-index: -1000 !important;
  display: flex;
  flex-direction: row-reverse;
  margin: 0px 0px;
  /* background-color: yellow; */
}

.pallet-row {
  /* margin: 0px 0.5px 0px 0.5px; */
  text-align: center;
  display: flex;
  flex-direction: row;
}

.pallet-col {
  display: flex;
  flex-direction: column;
}

.empty-pallet {
  opacity: 0;
}

.pallet-col-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.pallet-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.pallet {
  font-size: 0.15em;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pallet p {
  text-align: center;
  font-size: 1.2em;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.rotate-rack {
  transform: rotate(90deg) !important;
}
.rotate-rack-90 {
  transform: rotate(-90deg) !important;
}
.pallet-single {
  text-align: center;
  width: var(--block-single-stack-storage-width);
  height: var(--block-single-stack-storage-height);
}

.pallet-single p {
  text-align: center;
  font-size: 1em;
  line-height: 1.2em;
  white-space: nowrap;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

/* .leaflet-pane svg {
  position: relative;
  z-index: 2 !important;
} */

.double-deep-rack-size {
  text-align: center;
  width: var(--rack-storage-width);
  height: var(--rack-storage-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate-text-double-deep-rack {
  transform: rotate(-90deg);
}

.pallet-text-size {
  font-size: 0.18rem;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

.pink {
  background-color: pink;
}

.grey {
  background-color: grey;
}

.blue {
  background-color: blue;
}

.white {
  background-color: grey;
}

.yellow {
  background-color: yellow;
}

.pallet-double {
  text-align: center;
  width: var(--block-storage-width);
  height: var(--block-storage-height);
  font-size: 1.8px;
}

.non-lgv-icon {
  border: 3px solid var(--primary-blue);
  background-color: var(--white) !important;
  color: var(--black) !important;
  font-size: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 2000 !important;
}

.non-lgv-icon-style {
  font-family: "poppins";
  z-index: 2000 !important;
}

.pallet-single:has(.pallet-rotate-90, .pallet-rotate-negative-90) {
  width: var(
    --vblock-single-stack-storage-width,
    var(--block-single-stack-storage-width)
  );
  height: var(
    --vblock-single-stack-storage-height,
    var(--block-single-stack-storage-width)
  );
}

.pallet-double:has(.pallet-rotate-negative-90, .pallet-rotate-90) {
  width: var(--vblock-storage-width, var(--block-storage-width));
  height: var(--vblock-storage-height, var(--block-storage-height));
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

@media only screen and (max-width: 780px) {
  .a-status-section .a-status-box {
    min-width: 15vw;
  }
}

@media only screen and (min-width: 2560px) {
  .map-container {
    width: 98%;
    height: 50vh;
  }
}
