.rm-lgv-fleet-status-container {
  display: flex;
  flex-direction: column;
}

.fleet-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fleet-status {
  height: 52px;
  width: 418px;
  background-color: var(--error-red-lite);
  border-radius: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fleet-status img {
  margin: auto 10px;
}

.fleet-status p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.rm-status-container {
  margin: 30px 0px;
  background-color: var(--white);
  padding-left: 25px;
  border-radius: 10px;
  overflow-x: hidden;
}

.rm-loader {
  width: 99%;
  height: 100px;
  margin: 0px 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.rm-loader .spinner {
  margin-left: 10px;
}
.rm-header-section {
  display: flex;
  justify-content: flex-start;
}

.rm-header-section h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey);
}
.rm-status-section {
  display: flex;
  flex-direction: row;
  margin-top: -15px;
  padding: 5px 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.rm-status-section .status-box {
  min-width: 180px;
  max-height: 100px;
  margin-right: 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(191, 46, 58, 0.1);
  border: 1px solid var(--lite-border);
}

.rm-status-section .status-box .status-top-section {
  min-width: 100%;
  height: 25px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
}

.rm-status-section .status-box .status-top-section p {
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
  margin-top: 5px;
}

.rm-status-section .status-box .status-top-section span {
  font-weight: 500;
  font-size: 16px;
  color: var(--tertiary-white);
  margin-top: 5px;
}

.rm-status-duration-number {
  font-weight: 300;
  font-size: 40px;
  color: var(--black);
}

.rm-status-duration-duration {
  font-weight: 300;
  font-size: 20px;
  margin-top: 5px;
  margin-left: 10px;
  color: var(--black);
}

.rm-status-section-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.rm-no-data-spinner-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-removed-status-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-removed-status-container p {
  height: 50px;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

@media only screen and (max-width: 780px) {
  .a-status-section .a-status-box {
    min-width: 15vw;
  }
}

@media only screen and (max-width: 430px) {
  .rm-lgv-fleet-status-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  .fleet-status {
    margin: 0px 20px;
  }

  .rm-status-container {
    margin: 20px;
  }

  .rm-status-section {
    margin-left: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 330px) {
  .fleet-status p {
    font-size: 12px;
  }
  .rm-status-container {
    margin: 20px;
  }
  .rm-status-section {
    margin-left: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 2560px) {
  .rm-lgv-fleet-status-container {
    width: 99.5%;
    /* padding: 0px 0px 10px -20px; */
  }
  .rm-status-container {
    margin-right: 0px;
  }
}
