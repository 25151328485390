.yard-summary-modal-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  position: fixed;
  z-index: 1008;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
#yard-summary-modal-wrapper-id .yard-bottom-item-container {
  display: flex;
  width: 100%;
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
}

.yard-summary-modal {
  width: 50%;
  background-color: var(--liteBlue);
  border-radius: 25px;
  min-height: 170px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1007;
  padding: 40px;
}
/* at larger screen */
@media(min-width: 1440px)
{
  .yard-summary-modal{
    width:40%;
  }
}

.yard-summary-modal-top-section {
  flex: 0.1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -30px;
  margin-bottom: 20px;
}

.yard-summary-modal-top-section h3 {
  font-weight: 500;
}

.yard-summary-modal-top-section img {
  width: 42px;
  height: 42px;
  cursor: pointer;
}

.shift-container {
  background-color: var(--white);
  margin: 0px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.mt-20 {
  margin-top: 20px;
}

.shift-top-section {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.joint-btn {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  background-color: var(--faded-primary-blue);
}

.shift-on-button {
  width: 100px;
  height: 34px;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--primary-blue);
  z-index: 1003;
  margin: 0px !important;
  transition: all 0.5s ease-out;
}

.shift-off-button {
  width: 100px;
  height: 34px;
  border-radius: 10px;
  margin: 0px !important;
  color: var(--primary-blue);
  background-color: var(--faded-primary-blue);
}

.shift-bottom-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
}

.yard-summary-shift-box {
  display: flex;
  flex-direction: column;
  border: 0px !important;
  background-color: white;
  width: 131px;
  margin: 0px 10px 10px 0px;
  border-radius: 10px;
  height: 100px;
}

.yard-summary-shift-box-top-section {
  flex: 1;
  height: 60px;
  text-align: center;
}
.yard-summary-shift-box-bottom-section {
  flex: 1;
  height: 70px;
  text-align: center;
}

.yard-summary-shift-box-top-section p {
  font-size: 28px;
  text-align: center;
  font-weight: 300;
  color: var(--black);
}

.yard-summary-shift-box-bottom-section p {
  font-size: 11.5px;
  font-weight: 500;
  text-align: center;
  color: var(--black);
}

.shift-loader {
  width: 95%;
  height: 100px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.yard-summary-loader {
  margin: 20px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.shift-loader .spinner {
  margin-left: 10px;
}

.yard-summary-details-panel-table spinner {
  background-color: tomato;
}

.yard-summary-details-panel-wrapper {
  margin: 0px;
  border-radius: 10px;
}

.yard-summary-details-panel-table {
  width: 100%;
  text-align: left;
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 0 5px !important;
}

.panel-table-row {
  background-color: white;
}

.table-head {
  text-align: left;
  margin: 10px 0px;
}

.panel-table-row-cat {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.panel-table-row-val {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.panel-table-row td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.panel-table-row td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: right;
}

.panel-table-row-val {
  text-align: right;
}

.panel-table-row th,
td {
  padding: 10px;
  color: var(--grey);
}

.prevent-scroll {
  overflow: hidden;
  width: 100%;
}

/* @media only screen and (min-width: 2560px) {
  .yard-summary-modal-wrapper {
    width: 780px;
    height: 100%;
    background-color: transparent !important;
    display: flex;
    position: relative;
  }
  .yard-summary-modal {
    margin-right: 20px;
    width: 100%;
    margin-top: 10px;
    border-radius: 0px !important;
  }
} */
